<template>
  <b-container class="overflow-auto" fluid>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Upload Domain List</strong>
            </div>
          </div>
          <div class="card-body">
            <a-upload-dragger
              :action="domainListUploadAction"
              :headers="headers"
              :multiple="false"
              :beforeUpload="beforeUpload"
              :showUploadList=false
              name="File"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox"/>
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-text-secondary">
                  Max. size: 2MB
              </p>
            </a-upload-dragger>
            <div class="msg-box msg-box-flex">
              <a-icon class="mx-2" style="fontSize: 200%; opacity: 70%; color: #5481e1;" type="info-circle" />
              <p class="mt-3 ml-2">
                Domain list should be a text file containing <b>Domains</b> separated with <b>new lines</b>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ad Interface controls -->
    <a-row>
      <a-checkbox v-model="showActive">
        Show only active
      </a-checkbox>
    </a-row>
    <b-row>
      <!-- <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select>
          <columnSelect :storageKey="'display-domainlists-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col> -->
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-domainlist'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'ortb-domainlists-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :sort-compare="sortCompare"
      :bordered="true"
      :current-page="currentPage"
      :fields="filteredFields"
      :filter="filter"
      :hover="true"
      :items="showDomainLists"
      :per-page="perPage"
      :small="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :striped="true"
      :tbody-tr-class="rowClass"
      show-empty
      stacked="md"
      @filtered="onFiltered"
    >
      <template #cell(Global)="data">
        <a-checkbox :checked="(data.item.Global == 1)" @change="toggleDomainListGlobal(data.item)">
        </a-checkbox>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleDomainListStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(actions)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click.prevent="downloadDomainList(data.item.Id, data.item.Name)"
        >
          <i class="fa fa-download"></i>>
        </b-button>
        <router-link class="add-button" @click.stop :to="{ name: 'display-domainlist-update', params: { id: data.item.Id },  }">
          <b-button
            class="btn-info mr-1"
            size="sm"
            @click="editDomainList(data.item.Id)"
          >
            <i class="fa fa-edit"></i>
        </b-button>
      </router-link>
      </template>
      <template #cell(Id)="data">
        <div class="items-center">{{ data.item.Id }}</div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">{{ data.item.Name }}</div>
      </template>
      <template #cell(Rtbzones)="data">
        <div v-if="data.item.Rtbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in rtbzone.list">
                  {{
                    data.item.Rtbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Rtbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Remotertbzones)="data">
        <div v-if="data.item.Remotertbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in remotertbzone.list">
                  {{
                    data.item.Remotertbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Remotertbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getActionURL, downloadDomainListFile } from '@/api/display/domainlist'
import store from 'store'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    columnSelect,
    perPageSelect,
  },
  data() {
    return {
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          headerTitle: 'ID',
          sortable: true,
          thStyle: 'width:50px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Name',
          headerTitle: 'Domain List Name',
          show: true,
          sortable: true,
        },
        {
          key: 'Rtbzones',
          label: 'RTB Zones',
          show: true,
          headerTitle: 'RTB Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 90px',
        },
        {
          key: 'Remotertbzones',
          label: 'Remote RTB Zones',
          show: true,
          headerTitle: 'Remote RTB Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 150px',
        },
        {
          key: 'DomainCount',
          label: 'Num. of Domains',
          headerTitle: 'Number of Domains',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:150px',
        },
        {
          key: 'Global',
          label: 'Global',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
        // { key: 'Download', label: 'Download', show: true, class: 'text-center' },
      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      domainListUploadAction: getActionURL(),
      headers: {
        Authorization: `Bearer ${store.get('accessToken')}`,
      },
    }
  },
  computed: {
    ...mapState(['domainlist', 'settings', 'rtbzone', 'remotertbzone']),
    ...mapGetters('domainlist', ['getDomainLists', 'getActiveDomainLists']),
    showActive: {
      get: function () {
        return this.settings.showActiveDomainLists
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveDomainLists', value })
      },
    },
    showDomainLists() {
      return this.showActive ? this.getActiveDomainLists : this.getDomainLists
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showDomainLists.length
    },
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === 'Rtbzones' || key === 'Remotertbzones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    beforeUpload(file) {
      if (Math.round(file.size / 1024) <= 2048) {
        return true
      } else {
        Vue.prototype.$notification.error({
          message: 'File is too big',
          description: 'Maximum 2 MB allowed.',
        })
        return false
      }
    },
    downloadDomainList(id, name) {
      downloadDomainListFile(id, name)
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editDomainList(id) {
      this.$router.push({ name: 'display-domainlist-update', params: { id: id } }).catch(() => {})
    },
    toggleDomainListGlobal(domainlist) {
      Modal.confirm({
        title: domainlist.Global === 0 ? <p>Are you sure you want to change Domain list to global?<br/><br/><b>Global list will be applied to all traffic and will be used as a blacklist.</b></p> : 'Are you sure you want to change Domain list to specific?',
        onOk: function () {
          const newGlobal = domainlist.Global === 0 ? 1 : 0
          this.$store.dispatch('domainlist/CHANGE_GLOBAL', {
            id: domainlist.Id,
            global: newGlobal,
          })
        }.bind(this),
      })
    },
    toggleDomainListStatus(domainlist) {
      const status = domainlist.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Domain list status?',
        content: h => <div>{status} Domain list <strong>{domainlist.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = domainlist.Status === 0 ? 1 : 0
          this.$store.dispatch('domainlist/CHANGE_STATUS', {
            id: domainlist.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleChange(info) {
      const status = info.file.status
      if (status === 'done') {
        this.$notification.success({
          message: 'Successfully Uploaded',
          description: `${info.file.name} file uploaded successfully.`,
        })
        this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
      } else if (status === 'error') {
        this.$notification.error({
          message: 'Failed to upload',
          description: `${info.file.name} file upload failed.`,
        })
      }
    },
  },
  created() {
    this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    this.$store.dispatch('remotertbzone/LOAD_ZONES_SHORT')
  },
}
</script>

<style>
.msg-box-flex {
  display: flex;
  align-items: center;
  justify-content: start;
}
.msg-box {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 14px;
  margin-top: 15px;
  background-color: #fafafa;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #eae8e8;
}
.z-default {
  position: absolute;
  z-index: 0;
}
.z-above {
  position: relative;
  z-index: 1;
}
.ant-upload-text-secondary {
  font-size: 90%;
}
.ant-upload-drag-icon {
  margin-bottom: 15px !important;
}
@media only screen and (max-width: 768px) {
  .msg-box-flex {
    font-size: 75%;
  }
}
</style>
